import {
  CSSProperties,
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useState,
} from 'react'
import {
  ActionButton,
  FontSizes,
  IconButton,
  mergeStyles,
  NeutralColors,
  PrimaryButton,
  SharedColors,
  Stack,
  Text,
  TextField,
} from '@fluentui/react'
import { useNavigate } from 'react-router-dom'

import { deleteBtnStyles } from '@modules/sharedStyles'
import { PlaybooksSearchResults } from '@tests/fixtures/playbooks'
import { StyledDivider } from '@baseComponents/StyledDivider'
import { useContractTaskPaneViewed } from '@modules/analytics'
import { useTranslation } from '@hooks/useTranslation'
import AddAnotherTextField from '@components/AddAnotherTextField'
import BoldText from '@baseComponents/BoldText'
import Box from '@baseComponents/Box'
import Clipboard from '@components/Clipboard'
import ConfirmDialog from '@components/ConfirmDialog'
import ContentCard from '@components/ContentCard'
import ContractTitle from '@baseComponents/ContractTitle'
import NotificationBadge from '@components/NotificationBadge'
import ShowMore from '@components/ShowMore'
import SmallButton from '@components/SmallButton'
import StyledStack from '@components/StyledStack'
import TopNav from '@components/TopNav'
import UnstyledList from '@baseComponents/UnstyledList'

const contentCardStyles = { paddingBottom: 0 }
const contentTitleStyles = { fontSize: FontSizes.large }
const copiedMessageCloseDelay = 7000

// TODO: remove mock data after integrating with GetPlaybook endpoint
const playbook = PlaybooksSearchResults.results[0]
const extractedInstructionsMock = playbook.metadata.extracted_instructions.value
import { template as templateFixture } from '../../../../tests/fixtures/template'
import playbookContentFixture from '../../../../tests/fixtures/playbookContent.json'
import SmallActionButton from '@components/SmallActionButton'
type PlaybookClauseKey = keyof (typeof playbookContentFixture)[number]
type Clause = (typeof playbookContentFixture)[number]

export default function EditPlaybook() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const template = templateFixture
  const originalExtractedInstructions = extractedInstructionsMock
  const [extractedInstructions, setExtractedInstructions] = useState(extractedInstructionsMock)
  // TODO: once the NoS structured output is supported, use passed in playbookContent instead of fixture
  const [playbookContent, setPlaybookContent] = useState(playbookContentFixture)
  const [title, setTitle] = useState(playbook.documentName)
  const [deletePlaybookDialogHidden, setDeletePlaybookDialogHidden] = useState(true)
  const [deleteInstructionDialogHidden, setDeleteInstructionDialogHidden] = useState(true)
  const [selectedInstructionIndex, setSelectedInstructionIndex] = useState(-1)
  const selectedInstructionLabel = `${t('label.Instruction')} ${selectedInstructionIndex + 1}`
  const pageTitle = t('page.Playbook Details.Title')

  useContractTaskPaneViewed({ pageTitle, eventDetails: [playbook.documentId] })

  return (
    <>
      <TopNav title={pageTitle} prevPath="#/playbooks" />

      <StyledStack>
        <Stack.Item>
          <ContractTitle title={title} />
        </Stack.Item>

        <Stack.Item>
          <ContentCard
            title={t('page.Contracts.General Information')}
            style={contentCardStyles}
            titleStyle={contentTitleStyles}
          >
            <Metadata editable name="Playbook Name" value={title} onChange={updatePlaybookName} />
            <Metadata name="Contract Type" value={playbook.contractType} />
            <Metadata name="Party Role" value={playbook.metadata.playbook_party_role.value} />
            <Metadata name="Source Documents" style={{ marginBottom: 0 }} value={template.name} />
          </ContentCard>
        </Stack.Item>

        <Stack.Item>
          <ContentCard
            title={t('page.Playbook Details.Extracted Instructions')}
            style={contentCardStyles}
            titleStyle={contentTitleStyles}
          >
            {extractedInstructions.map((value, i) => (
              <Metadata
                editable
                removable
                multiline
                key={`${i}-${value}`}
                value={value}
                name={`${t('label.Instruction')} ${i + 1}`}
                unsaved={!value || !originalExtractedInstructions.includes(value)}
                dialogHiddenDefault={!!value}
                onRemove={() => confirmRemoveContractInstruction(i)}
                onChange={v => updateContractInstructions(i, v)}
                onDismiss={v => !v?.trim() && removeContractInstruction(i)}
              >
                <ShowMore content={value} />
              </Metadata>
            ))}

            <Stack.Item style={{ display: 'flex', justifyContent: 'end' }}>
              <ActionButton
                iconProps={{ iconName: 'Add' }}
                onClick={() => setExtractedInstructions([...extractedInstructions, ''])}
                style={{ marginTop: '0.3em' }}
              >
                Add Instruction
              </ActionButton>
            </Stack.Item>
          </ContentCard>
        </Stack.Item>

        <Stack.Item>
          <ContentCard
            title={t('page.Playbook Details.Clause Content')}
            style={contentCardStyles}
            titleStyle={contentTitleStyles}
          >
            <UnstyledList>
              {playbookContent.map((clause, i) => (
                <Metadata
                  editable
                  multiline
                  key={i}
                  name={clause['Clause Title']}
                  editDialogTitle={t('page.Playbook Details.Clause Content')}
                  showContentDivider={i < playbookContent.length - 1}
                  collapsedContent={<ClauseContent key={i} clause={clause} />}
                  CustomEditor={({ setValue }) => (
                    <EditClauseContent
                      clause={clause}
                      setValue={setValue}
                      onChange={updateClause}
                    />
                  )}
                >
                  <div>
                    <Text>{t('page.Playbook Details.Alternative Clauses')}</Text>
                    <NotificationBadge style={{ marginLeft: '0.3em' }}>
                      {findAlternateClauses(clause).length}
                    </NotificationBadge>
                  </div>
                </Metadata>
              ))}
            </UnstyledList>
          </ContentCard>
        </Stack.Item>

        <Stack.Item
          style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '0.3em' }}
        >
          <IconButton
            title={`${t('button.Delete')} ${playbook.documentName}`}
            iconProps={{ iconName: 'Delete' }}
            onClick={() => setDeletePlaybookDialogHidden(false)}
            style={{ color: SharedColors.red20 }}
          />
          <PrimaryButton>{t('button.Check Compliance')}</PrimaryButton>
        </Stack.Item>
      </StyledStack>

      <ConfirmDialog
        hidden={deletePlaybookDialogHidden}
        title={t('label.Confirm Delete')}
        confirm={t('page.Playbook Details.Delete Playbook')}
        btnStyles={deleteBtnStyles}
        onDismiss={() => setDeletePlaybookDialogHidden(true)}
        onConfirm={() => {
          // TODO: integrate with backend
          navigate('/playbooks')
        }}
      >
        Confirm deletion of {playbook.documentName}
      </ConfirmDialog>

      <ConfirmDialog
        hidden={deleteInstructionDialogHidden}
        title={t('label.Confirm Delete')}
        confirm={`${t('button.Delete')} ${selectedInstructionLabel}`}
        btnStyles={deleteBtnStyles}
        onDismiss={() => setDeleteInstructionDialogHidden(true)}
        onConfirm={() => removeContractInstruction(selectedInstructionIndex)}
      >
        <BoldText>{selectedInstructionLabel}</BoldText>
        <ShowMore
          content={extractedInstructions[selectedInstructionIndex] ?? ''}
          style={{ display: 'block', marginTop: '0.3em' }}
        />
      </ConfirmDialog>
    </>
  )

  async function updatePlaybookName(value?: string) {
    // TODO: integrate with backend
    setTitle(value ?? '')
    return true
  }

  async function updateContractInstructions(i: number, value?: string) {
    // TODO: integrate with backend
    const newValues = structuredClone(extractedInstructions)
    newValues[i] = value ?? ''
    setExtractedInstructions(newValues)
    return true
  }

  async function confirmRemoveContractInstruction(i: number) {
    setSelectedInstructionIndex(i)
    setDeleteInstructionDialogHidden(false)
  }

  async function removeContractInstruction(i: number) {
    // TODO: integrate with backend
    const newValues = structuredClone(extractedInstructions)
    newValues.splice(i, 1)
    setExtractedInstructions(newValues)
    setDeleteInstructionDialogHidden(true)
  }

  async function updateClause(clauseTitle: string, value: string, index: number) {
    const clause = playbookContent.find(c => c['Clause Title'] === clauseTitle)

    if (!clause) throw Error(`Playbook update failed. Clause missing.`)

    // TODO: integrate with backend
    const alternateKey = `Alternate ${index + 1}` as PlaybookClauseKey
    clause[alternateKey] = value
    setPlaybookContent(playbookContent)
  }
}

type MetadataProps = PropsWithChildren & {
  name: string
  value?: string
  unsaved?: boolean
  editable?: boolean
  removable?: boolean
  multiline?: boolean
  editDialogTitle?: string
  onChange?: (v?: string) => Promise<boolean>
  onRemove?: (v?: string) => void
  onDismiss?: (v?: string) => void
  CustomEditor?: (props: EditorProps) => JSX.Element
  dialogHiddenDefault?: boolean
  collapsedContent?: string | ReactNode
  showContentDivider?: boolean
  style?: CSSProperties
}

function Metadata(props: MetadataProps) {
  const { t } = useTranslation()
  const [dialogHidden, setDialogHidden] = useState(props.dialogHiddenDefault)
  const [collapsed, setCollapsed] = useState(!!props.collapsedContent)
  const title = `${t(`button.${props.unsaved ? 'Add' : 'Edit'}`)} ${props.name}`
  const removeTitle = `${t('button.Delete')} ${props.name}`
  const collapseIcon = collapsed ? 'ChevronRight' : 'ChevronDown'
  const cursor = props.collapsedContent ? 'pointer' : 'inherit'
  const collapsibleBoxClass = mergeStyles({
    ':hover, :active': { background: props.collapsedContent && NeutralColors.gray30 },
  })

  return (
    <Stack style={props.style}>
      <Stack
        horizontal
        onClick={() => setCollapsed(!collapsed)}
        style={{ alignItems: 'baseline', paddingBottom: '0.5em', cursor }}
        className={collapsibleBoxClass}
      >
        {props.collapsedContent && (
          <SmallActionButton iconName={collapseIcon} onClick={() => setCollapsed(!collapsed)} />
        )}
        <div style={{ width: '100%' }}>
          <Stack horizontal style={{ justifyContent: 'space-between', alignItems: 'baseline' }}>
            <BoldText>{props.name}</BoldText>
            <Stack horizontal>
              {props.removable && (
                <SmallButton
                  title={removeTitle}
                  btnSize="medium"
                  variant="destructive"
                  iconName="Trash"
                  onClick={() => props.onRemove?.(props.value)}
                />
              )}
              <EditButton
                title={title}
                show={props.editable}
                onClick={() => setDialogHidden(false)}
              />
            </Stack>
          </Stack>
          {props.children ?? props.value}
        </div>
      </Stack>

      {props.collapsedContent && !collapsed && (
        <Stack style={{ marginBottom: '0.3em' }}>{props.collapsedContent}</Stack>
      )}
      {props.showContentDivider && <StyledDivider />}

      <EditDialog
        title={props.editDialogTitle ?? title}
        label={props.name}
        multiline={props.multiline}
        value={props.value ?? ''}
        hidden={dialogHidden}
        setHidden={setDialogHidden}
        onChange={props.onChange}
        onDismiss={props.onDismiss}
        CustomEditor={props.CustomEditor}
      />
    </Stack>
  )
}

type ClauseContentProps = {
  clause: Clause
}

function ClauseContent({ clause }: ClauseContentProps) {
  const alternates = findAlternateClauses(clause)

  return (
    <UnstyledList>
      {alternates.map(([label, alternate], i) => {
        return (
          <li key={i}>
            <Box style={{ paddingBottom: '0.3em' }}>
              <BoldText>{label}</BoldText>
              <ShowMore content={alternate} style={{ display: 'block' }} />
              <Stack style={{ alignItems: 'end' }}>
                <Clipboard
                  text={alternate}
                  successDuration={copiedMessageCloseDelay}
                  style={{ margin: '0.3em 0', paddingRight: 0 }}
                />
              </Stack>
            </Box>
          </li>
        )
      })}
    </UnstyledList>
  )
}

type EditClauseContentProps = {
  clause: Clause
  setValue: Dispatch<SetStateAction<string>>
  onChange: (clauseTitle: string, value: string, index: number) => void
}

function EditClauseContent({ clause, setValue, onChange }: EditClauseContentProps) {
  const { t } = useTranslation()
  const values = findAlternateClauses(clause).map(([_, v]) => v)

  return (
    <Stack style={{ gap: '1em' }}>
      <Stack.Item>
        <BoldText>Clause Title</BoldText>
        <div>{clause['Clause Title']}</div>
      </Stack.Item>
      <Stack.Item>
        <BoldText>Clause Text</BoldText>
        <div>{clause['Template Citation']}</div>
      </Stack.Item>
      <StyledDivider />

      <AddAnotherTextField
        multiline
        autoAdjustHeight
        maxFields={2}
        values={values}
        labelPrefix={t('page.Playbook Details.Alternative Clause')}
        addBtnLabel={t('page.Playbook Details.Add Alternative Clause')}
        btnStyles={{ alignSelf: 'end' }}
        onValueChange={(values, index) => {
          const value = values[index]
          setValue(value)
          onChange(clause['Clause Title'], value, index)
        }}
      />
    </Stack>
  )
}

type EditDialogProps = {
  title: string
  label: string
  value: string
  setHidden: Dispatch<SetStateAction<boolean | undefined>>
  onChange?: (v?: string) => Promise<boolean>
  onDismiss?: (v?: string) => void
  CustomEditor?: (props: EditorProps) => JSX.Element
  multiline?: boolean
  hidden?: boolean
}
type EditorProps = {
  value: string
  setValue: Dispatch<SetStateAction<string>>
}

function EditDialog(props: EditDialogProps) {
  const { t } = useTranslation()
  const [internalValue, setInternalValue] = useState(props.value)
  const Editor = props.CustomEditor ? props.CustomEditor : DefaultEditor

  return (
    <ConfirmDialog
      title={props.title}
      hidden={props.hidden}
      confirm={t('button.Save')}
      onConfirm={async () => {
        // TODO integrate with backend
        try {
          await props.onChange?.(internalValue)
          props.setHidden(true)
        } catch (e) {
          // TODO: set error
          console.debug(e)
        }
      }}
      onDismiss={() => {
        props.onDismiss?.(internalValue)
        props.setHidden(true)
      }}
    >
      <Editor value={internalValue} setValue={setInternalValue} />
    </ConfirmDialog>
  )

  function DefaultEditor({ value, setValue }: EditorProps) {
    const [internalValue, setInternalValue] = useState(value)

    return (
      <Stack.Item>
        <TextField
          autoFocus
          label={props.label}
          value={internalValue}
          multiline={props.multiline}
          autoAdjustHeight={props.multiline}
          onChange={(_e, newValue) => setInternalValue(newValue ?? '')}
          onBlur={() => setValue(internalValue)}
        />
      </Stack.Item>
    )
  }
}

type EditButtonProps = {
  title: string
  onClick: () => void
  show?: boolean
}

function EditButton({ title, onClick, show }: EditButtonProps) {
  return (
    <SmallButton
      title={title}
      onClick={onClick}
      iconProps={{ iconName: 'Edit' }}
      style={{ visibility: show ? 'visible' : 'hidden' }}
      ariaHidden={!show}
    />
  )
}

function findAlternateClauses(clause: Clause) {
  return Object.entries(clause).filter(([key, val]) => key.startsWith('Alternate') && val?.trim())
}
