import { ActionButton, MessageBar, MessageBarType, NeutralColors } from '@fluentui/react'
import useClipboard from 'react-use-clipboard'

import { ClauseIssue } from '@pages/PlaybooksComplianceSummary'
import { useTranslation } from '@hooks/useTranslation'
import LongTextWithLabel from '@components/LongTextWithLabel'

type Props = {
  issue: ClauseIssue
}

export default function ClauseIssueContent(props: Props) {
  const { issue } = props
  const [copied, setCopied] = useClipboard(issue.rewrittenClause)
  const { t } = useTranslation()

  return (
    <div style={{ padding: '0.5em 1em 2em 1.5em' }}>
      <LongTextWithLabel label={'Clause Summary'} content={issue.contractClause} />
      <LongTextWithLabel label={'Playbook Summary'} content={issue.playbookSummary} />
      <LongTextWithLabel label={'Key Differences'} content={issue.differences} />

      <div style={{ background: NeutralColors.gray20, margin: '1em -1em 0', padding: '1em 1em' }}>
        <LongTextWithLabel label={'Rewritten Clause'} content={issue.rewrittenClause} />

        <div style={{ textAlign: 'right' }}>
          <ActionButton
            onClick={setCopied}
            iconProps={{ iconName: 'Copy' }}
            style={{
              margin: '0 0.3em',
            }}
          >
            Copy
          </ActionButton>
        </div>
        <div style={{ margin: '0 -1em -1em' }}>
          {copied && (
            <MessageBar messageBarType={MessageBarType.success}>
              {t('label.copy-message')}
            </MessageBar>
          )}
        </div>
      </div>
    </div>
  )
}
