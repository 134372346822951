import { CSSProperties } from 'react'
import { useNavigate } from 'react-router'
import {
  FontSizes,
  FontWeights,
  IButtonProps,
  NeutralColors,
  Pivot,
  PivotItem,
  SharedColors,
  Stack,
} from '@fluentui/react'

import TopNav from '@components/TopNav'
import StyledStack from '@components/StyledStack'
import { useContractTaskPaneViewed } from '@modules/analytics'
import ContractTitle from '@baseComponents/ContractTitle'
import { useTranslation } from '@hooks/useTranslation'
import { PlaybooksComplianceResponse } from '@fixtures/PlaybooksComplianceResponse'
import UnstyledList from '@baseComponents/UnstyledList'
import CollapsibleItem from '@components/CollapsibleItem'
import BoldText from '@baseComponents/BoldText'
import { titleize } from '@modules/utils'
import ClauseIssueContent from '@components/ClauseIssueContent'
import TextBadge from '@components/TextBadge'
import Box from '@baseComponents/Box'
import LongTextWithLabel from '@components/LongTextWithLabel'

export type ClauseIssue = {
  clauseTitle: string
  contractClause: string
  contractSummary: string
  playbookSummary: string
  differences: string
  rewrittenClause: string
  issueType: string
  importantClause: boolean
}

const badgeStyle: CSSProperties = {
  fontSize: FontSizes.size12,
  backgroundColor: SharedColors.gray10,
  borderColor: SharedColors.gray10,
  whiteSpace: 'nowrap',
  display: 'block',
  marginTop: '0.5em',
}

const disabledPivotStyle: IButtonProps = {
  disabled: true,
  style: { color: SharedColors.gray20 },
}

const pageTitle = 'Compliance'

export default function PlaybooksComplianceSummary() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const subtitle = t('page.Playbooks Compliance Summary.subtitle')
  const { nonCompliant: issues, compliant } = PlaybooksComplianceResponse

  useContractTaskPaneViewed({ pageTitle: `${pageTitle} ${subtitle}` })

  return (
    <>
      <TopNav title={pageTitle} prevPath={() => navigate(-1)} showAIGeneratedBadge />
      <StyledStack style={{ paddingBottom: '3em' }}>
        <ContractTitle title={subtitle} style={{ fontWeight: FontWeights.bold }} />
        {renderContent()}
      </StyledStack>
    </>
  )

  function renderContent() {
    return (
      <>
        <Stack.Item style={{ margin: '0 1em 1em 0' }}>
          {t('page.Playbooks Compliance Summary.blurb')}
        </Stack.Item>
        <Stack.Item style={{ margin: '0 1em 1em 0' }}>
          {t('page.Playbooks Compliance Summary.blurb2')}
        </Stack.Item>
        <Stack.Item>
          <Pivot
            aria-label="Compliance"
            defaultSelectedKey={getFirstNonEmptyTab()}
            styles={{ root: { display: 'flex', justifyContent: 'center', marginBottom: '1.5em' } }}
          >
            <PivotItem
              itemKey={'0'}
              headerText="Issues"
              itemCount={issues.length}
              headerButtonProps={issues.length ? undefined : disabledPivotStyle}
            >
              <Stack.Item>
                {renderIssuesList(
                  'Primary Issues',
                  issues.filter(c => c.importantClause),
                )}
              </Stack.Item>
              <Stack.Item>
                {renderIssuesList(
                  'Secondary Issues',
                  issues.filter(c => !c.importantClause),
                )}
              </Stack.Item>
            </PivotItem>
            <PivotItem
              itemKey={'1'}
              headerText="Compliant"
              itemCount={compliant.length}
              headerButtonProps={compliant.length ? undefined : disabledPivotStyle}
            >
              <Stack.Item>{renderCompliantList()}</Stack.Item>
            </PivotItem>
          </Pivot>
        </Stack.Item>
      </>
    )
  }

  function getFirstNonEmptyTab() {
    return [issues, compliant].findIndex(arr => arr.length > 0).toString()
  }

  function renderIssuesList(title: string, issues: ClauseIssue[]) {
    if (!issues.length) return null

    return (
      <Box>
        <ContractTitle style={{ margin: '0 0 0.3em' }} title={title} />
        <UnstyledList>
          {issues.map((item, idx) => {
            const isLastItem = idx === issues.length - 1
            return (
              <CollapsibleItem
                key={idx.toString()}
                item={{ ...item, key: idx.toString(), active: false }}
                itemHeader={() => issueHeader(item)}
                itemContent={() => <ClauseIssueContent issue={item} />}
                iconStyles={{ marginBottom: '2em', paddingLeft: 0 }}
                listItemStyles={isLastItem ? { borderBottom: 'none' } : undefined}
              />
            )
          })}
        </UnstyledList>
      </Box>
    )
  }

  function renderCompliantList() {
    return (
      <UnstyledList style={{ padding: '0 0.5em 0' }}>
        {compliant.map(({ clauseTitle, explanation }, idx) => {
          const isLastItem = idx === compliant.length - 1
          return (
            <li key={idx}>
              <LongTextWithLabel
                label={clauseTitle}
                content={explanation}
                icon={'Checkmark'}
                styles={{
                  root: isLastItem
                    ? { borderBottom: 'none' }
                    : { borderBottom: `1px solid ${NeutralColors.gray50}`, marginBottom: '1em' },
                  icon: { color: SharedColors.green20 },
                }}
              />
            </li>
          )
        })}
      </UnstyledList>
    )
  }

  function issueHeader({ clauseTitle, issueType }: ClauseIssue) {
    return (
      <span style={{ position: 'relative' }}>
        <BoldText style={{ marginRight: '0.3em' }}>{titleize(clauseTitle)}</BoldText>
        <TextBadge style={badgeStyle}>{issueType}</TextBadge>
      </span>
    )
  }
}
