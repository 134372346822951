import React from 'react'
import { PropsWithChildren } from 'react'
import { ActionButton, Dialog, DialogFooter, IButtonStyles, PrimaryButton } from '@fluentui/react'

type Props = PropsWithChildren & {
  title: string
  onConfirm: (...args: any) => void
  onDismiss: () => void
  onDismissed?: () => void
  hidden?: boolean
  confirm?: string
  disableBtn?: boolean
  confirmBtnTitle?: string
  btnStyles?: Partial<IButtonStyles>
}

const ConfirmDialog: React.FC<Props> = ({
  title,
  confirm,
  children,
  hidden,
  disableBtn,
  onConfirm,
  onDismiss,
  onDismissed,
  confirmBtnTitle,
  btnStyles,
}) => {
  return (
    <Dialog
      hidden={hidden}
      onDismiss={onDismiss}
      dialogContentProps={{
        title,
      }}
      modalProps={{
        isBlocking: true,
        onDismissed: onDismissed,
      }}
    >
      {children}
      <DialogFooter>
        <PrimaryButton
          onClick={onConfirm}
          disabled={disableBtn}
          title={confirmBtnTitle}
          styles={btnStyles}
        >
          {confirm}
        </PrimaryButton>
        <ActionButton onClick={onDismiss}>Cancel</ActionButton>
      </DialogFooter>
    </Dialog>
  )
}

export default ConfirmDialog
