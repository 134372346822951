import { useState } from 'react'
import { ActionButton, IStyle, ITextFieldProps, Stack, TextField } from '@fluentui/react'

import SmallButton from '@components/SmallButton'
import BoldText from '@baseComponents/BoldText'
import useLoadingFadeStyle from '@hooks/useLoadingFadeStyle'

type Props = ITextFieldProps & {
  labelPrefix: string
  values: string[]
  onValueChange: (values: string[], index: number) => void
  addBtnLabel?: string
  maxFields?: number
  loading?: boolean
  btnStyles?: IStyle
}
const empty = ['']

export default function AddAnotherTextField({
  labelPrefix,
  values,
  loading,
  disabled,
  placeholder,
  onValueChange,
  maxFields,
  btnStyles,
  addBtnLabel = 'Add another',
  ...textFieldProps
}: Props) {
  const [inputs, setInputs] = useState(values.length ? values : empty)
  const loadingFade = useLoadingFadeStyle(loading || disabled)

  return (
    <>
      {inputs.map((value, i) => {
        const label = `${labelPrefix} ${i + 1}`
        return (
          <div key={i} style={{ ...loadingFade, marginTop: i > 0 ? '1em' : '' }}>
            <Stack
              horizontal
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '0.3em',
              }}
            >
              <BoldText>{label}</BoldText>
              <SmallButton
                iconName="Cancel"
                variant="destructive"
                title={`Remove ${label}`}
                onClick={() => removeValue(i)}
              />
            </Stack>

            <TextField
              value={value}
              disabled={loading}
              autoFocus={i === 0}
              placeholder={placeholder}
              onChange={(_, value) => {
                const newInputs = structuredClone(inputs)
                newInputs.splice(i, 1, value ?? '')
                setInputs(newInputs)
                onValueChange(newInputs, i)
              }}
              {...textFieldProps}
            />
          </div>
        )
      })}

      <ActionButton
        // @ts-ignore ts complains btnStyles is not spreadable but it is
        styles={{ root: { paddingLeft: 0, ...loadingFade, ...btnStyles } }}
        iconProps={{ iconName: 'Add' }}
        disabled={maxFields ? inputs.length >= maxFields : false}
        onClick={() => setInputs([...inputs, ''])}
      >
        {addBtnLabel}
      </ActionButton>
    </>
  )

  function removeValue(i: number) {
    if (i == 0 && inputs.length === 1) {
      setInputs(empty)
      onValueChange(empty, i)
      return
    }
    const newInputs = structuredClone(inputs)
    newInputs.splice(i, 1)
    setInputs(newInputs)
    onValueChange(newInputs, i)
  }
}
