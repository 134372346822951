import { ActionButton, IIconProps, IButtonProps, FontSizes } from '@fluentui/react'

type Props = IButtonProps & {
  iconName: IIconProps['iconName']
}

export default function SmallActionButton({ iconName, onClick, ...props }: Props) {
  return (
    <ActionButton
      iconProps={{ iconName }}
      onClick={onClick}
      style={{ height: 'auto', width: 'auto', padding: 0 }}
      styles={{ icon: { fontSize: FontSizes.small } }}
      {...props}
    />
  )
}
