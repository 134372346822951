export const PlaybooksComplianceResponse = {
  nonCompliant: [
    {
      clauseTitle: 'Term',
      contractClause: 'The term of this Agreement shall commence on the date that it is made and shall continue until the earlier of thirty-six (36) months or until the completion of all Services under all Statements of Work, unless sooner terminated pursuant to Section 10. (Section 3)',
      contractSummary: 'Agreement term is 36 months or until completion of services',
      playbookSummary: 'Initial term should be 5 years or longer with automatic yearly renewal unless terminated with 30 day notice',
      differences: 'Contract provides for shorter fixed term of 36 months which aligns with Alternate 2 position (fixed term <3 years). No automatic renewal provision.',
      rewrittenClause: 'The term of this Agreement shall commence on the date that it is made and shall continue for an initial term of five (5) years, automatically renewing for successive one (1) year periods thereafter unless either party provides written notice of non-renewal at least 30 days prior to the end of the then-current term, or until earlier terminated pursuant to Section 10.',
      issueType: 'Clause Language Issue',
      importantClause: true
    },
    {
      clauseTitle: 'Warranties',
      contractClause: 'BioAgilytix makes NO WARRANTIES, EXPRESS, OR IMPLIED, CONCERNING THE RESULT OF THIS STUDY OR, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE OF SUCH RESULTS. BioAgilytix shall not be liable for any direct, consequential, or other damages suffered by ENGENE or any others as a result of this Agreement. (Section 10)',
      contractSummary: 'BioAgilytix disclaims all warranties and liability for damages',
      playbookSummary: 'Disclaimer should disclaim all warranties and specifically disclaim implied warranties of merchantability, fitness, title, non-infringement and accuracy',
      differences: 'Contract\'s warranty disclaimer is narrower in scope than playbook\'s acceptable position, focusing only on study results rather than all confidential information. Missing specific disclaimers for title and non-infringement.',
      rewrittenClause: 'BIOAGILYTIX PROVIDES ALL SERVICES, WORK PRODUCT AND RESULTS ON AN \'AS IS\' BASIS, WITHOUT WARRANTIES OF ANY KIND. BIOAGILYTIX EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, OR ACCURACY.',
      issueType: 'Clause Language Issue',
      importantClause: true
    },
    {
      clauseTitle: 'Governing Law & Jurisdiction',
      contractClause: 'This Agreement shall be governed by the laws of the State of Massachusetts. (Section 16)',
      contractSummary: 'Agreement governed by Massachusetts law',
      playbookSummary: 'Agreement should be governed by New York or Delaware law with exclusive jurisdiction in state and federal courts in New York County/Delaware',
      differences: 'Contract specifies Massachusetts law rather than acceptable New York or Delaware law options. No specific venue provision for disputes.',
      rewrittenClause: 'This Agreement shall be governed by and construed in accordance with the laws of Delaware, without regard to conflicts of law principles. Any dispute arising out of or relating to this Agreement shall be subject to the exclusive jurisdiction of the state and federal courts located in Delaware.',
      issueType: 'Clause Language Issue',
      importantClause: true
    },
    {
      clauseTitle: 'Warranties',
      contractClause: 'BioAgilytix makes NO WARRANTIES, EXPRESS, OR IMPLIED, CONCERNING THE RESULT OF THIS STUDY OR, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE OF SUCH RESULTS. BioAgilytix shall not be liable for any direct, consequential, or other damages suffered by ENGENE or any others as a result of this Agreement. (Section 10)',
      contractSummary: 'BioAgilytix disclaims all warranties and liability for damages',
      playbookSummary: 'Disclaimer should disclaim all warranties and specifically disclaim implied warranties of merchantability, fitness, title, non-infringement and accuracy',
      differences: 'Contract\'s warranty disclaimer is narrower in scope than playbook\'s acceptable position, focusing only on study results rather than all confidential information. Missing specific disclaimers for title and non-infringement.',
      rewrittenClause: 'BIOAGILYTIX PROVIDES ALL SERVICES, WORK PRODUCT AND RESULTS ON AN \'AS IS\' BASIS, WITHOUT WARRANTIES OF ANY KIND. BIOAGILYTIX EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, OR ACCURACY.',
      issueType: 'Clause Language Issue',
      importantClause: false
    },
    {
      clauseTitle: 'Governing Law & Jurisdiction',
      contractClause: 'This Agreement shall be governed by the laws of the State of Massachusetts. (Section 16)',
      contractSummary: 'Agreement governed by Massachusetts law',
      playbookSummary: 'Agreement should be governed by New York or Delaware law with exclusive jurisdiction in state and federal courts in New York County/Delaware',
      differences: 'Contract specifies Massachusetts law rather than acceptable New York or Delaware law options. No specific venue provision for disputes.',
      rewrittenClause: 'This Agreement shall be governed by and construed in accordance with the laws of Delaware, without regard to conflicts of law principles. Any dispute arising out of or relating to this Agreement shall be subject to the exclusive jurisdiction of the state and federal courts located in Delaware.',
      issueType: 'Clause Language Issue',
      importantClause: false
    }
  ],
  compliant: [
    {
      clauseTitle: 'Confidential Information Definition',
      explanation: 'Contract broadly defines confidential information to include all information furnished before or after agreement date in any form.',
      contractClause: '\'Confidential Information\' means all information furnished by one party or its representatives, counsel, directors, officers, employees or agents (collectively, the \'Representatives\') to the other party relative to the purpose of BioAgilytix performing Services under this Agreement or a Statement of Work hereto, and whether disclosed or provided before or after the Effective Date and whether disclosed or provided in oral, written, graphic, electronic, photographic or any other form... (Section 7.E.i)'
    },
    {
      clauseTitle: 'Exceptions to Confidential Information',
      explanation: 'Contract includes standard exceptions for public domain, independent development, and third party receipt.',
      contractClause: 'Confidential Information shall not include any information that the receiving party can prove by competent evidence: (a) was already known to the receiving party without any obligations of confidentiality prior to receipt from the other party; (b) was generally available to the public or otherwise part of the public domain at the time of its disclosure to the receiving party; (c) became generally available to the public or otherwise part of the public domain after its disclosure, other than through any act or omission of the receiving party in breach of any obligation of confidentiality; (d) was disclosed to the receiving party, other than under an obligation of confidentiality, by a third party who had no obligation not to disclose such information to others... (Section 7.B)'
    },
    {
      clauseTitle: 'No Publicity',
      explanation: 'Contract prohibits publication or publicity without express written consent.',
      contractClause: 'Neither party shall use the name, tradenames or trademarks of the other party or the other party\'s employees in connection with any products, promotion, or advertising without the prior written permission of an authorized representative of the other party. (Section 17)'
    }
  ],
  notReviewed: [
    'Services', 'Work Product', 'Compensation', 'Payment Terms', 'Equipment and Reagents', 'Required Disclosure', 'Publication', 'Indemnification', 'Amendment, Termination and Survival', 'Notices', 'Independent Contractor', 'Independent Inquiry', 'Force Majeure', 'Severability', 'Headings', 'Entire Agreement', 'Assignment', 'Quality Agreement', 'Conflicting Terms'
  ]
}