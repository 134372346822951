export const template = {
  id: '123',
  name: 'PDF Template 1',
  mimeType: 'application/pdf',
  resourceType: 'template_document',
  author: 'Author Name',
  links: { self: '', metadata: '', content: '' },
  updated: '2024-04-01T20:32:02.658',
  created: '',
  metadata: {
    userMetadata: {
      contract_type: '',
      description: 'des',
    },
    systemMetadata: {},
    customMetadata: { digests: [] },
  },
}
