import { FacetResult, PlaybookResource, SearchResult } from '@blaw/contracts-api-schema'
import { FilterState } from '@contexts/KeyTermsContext'
import usePagination, { PaginationComponent, PaginationMeta } from '@hooks/usePagination'
import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react'

import { PlaybooksSearchResults } from '@fixtures/playbooks'
import { FacetListItem } from '@modules/ClauseAnalyzer'
import { t } from 'i18next'
import ApiClient from '@modules/ApiClient'
import { StoreContext } from '@contexts/StoreContext'

interface Props {
  children?: any
  fetchingItemsDefault?: boolean
}
export interface PlaybooksContextState {
  query: string
  setQuery: Dispatch<SetStateAction<string>>
  error?: string
  setError: Dispatch<SetStateAction<string | undefined>>
  warn: string
  Pagination: PaginationComponent
  pageNum: number
  topOfPageRef: MutableRefObject<HTMLDivElement | null>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  deleteModalHidden: boolean
  setDeleteModalHidden: Dispatch<SetStateAction<boolean>>
  deleting: boolean
  playbooks: PlaybookResource[]
  setPlaybooks: Dispatch<SetStateAction<PlaybookResource[]>>
  fetchPlaybooks: (query: string, page: number, filters: FilterState) => Promise<PlaybooksResult>
  loadPage: (query: string) => void
  loadNextPage: (page: number) => void
  loadFirstPage: (...args: any) => Promise<void>
  fetchingItems: MutableRefObject<boolean>
  deleteSelectedPlaybook: (id: string) => void
}

const playbooksPerPage = 20

type PlaybooksResult = {
  playbooks: PlaybookResource[]
  meta: PaginationMeta
  items: {
    facets: FacetResult[]
    count: number
  }
  warn?: string
  error?: string
}

type PlaybooksResponse = {
  facets: FacetListItem[]
  count: number
}

export const PlaybooksContext = createContext({} as PlaybooksContextState)

function PlaybooksContextProvider(props: Props) {
  const [query, setQuery] = useState('')
  const [error, setError] = useState<string>()
  const [warn, setWarn] = useState('')
  const [loading, setLoading] = useState(true)
  const [deleteModalHidden, setDeleteModalHidden] = useState(true)
  const [deleting, setDeleting] = useState(false)
  const [playbooks, setPlaybooks] = useState<PlaybookResource[]>([])
  const [, setMeta] = useState<PaginationMeta>()
  const [count, setCount] = useState<number>(0)
  const fetchingItems = useRef(props.fetchingItemsDefault ?? false)
  const { routes, storeSessionInfo } = useContext(StoreContext)
  const apiClient = new ApiClient(storeSessionInfo, setError)

  const { loadFirstPage, Pagination, pageNum } = usePagination({
    loadPage,
    loadNextPage,
    hitsPerPage: playbooksPerPage,
    scrollToTop: () => topOfPageRef.current && topOfPageRef.current.scrollIntoView(),
  })

  useEffect(() => {
    loadFirstPage(query)
  }, [query])

  const topOfPageRef = useRef<null | HTMLDivElement>(null)

  // TODO: Call services API to fetch playbooks
  async function fetchPlaybooks(query: string, page: number) {
    const result: PlaybooksResult = {
      playbooks: [],
      meta: { totalPages: 0, totalHits: 0 },
      items: { facets: [], count: 0 },
    }
    const playbooks = (PlaybooksSearchResults.results as SearchResult[]).map(searchResultToResource)
    const meta = { totalPages: 1, totalHits: 2 }

    if (playbooks?.length) {
      setPlaybooks(playbooks)
      setMeta(meta)
      setCount(3)
      result.meta = meta
      result.playbooks = playbooks
    } else {
      setCount(0)
      result.warn = t('page.Contracts.No Results Warning')
    }
    return result
  }

  function searchResultToResource(searchResult: SearchResult): PlaybookResource {
    const { metadata } = searchResult
    return {
      id: searchResult.documentId,
      name: searchResult.documentName,
      resourceType: 'template_document',
      createdBy: 'test',
      dateModified: new Date(),
      dateCreated: new Date(),
      metadata: {
        userMetadata: {
          contract_type: metadata.contract_type?.value ?? '',
          description: metadata.description?.value ?? '',
          first_draft_origin: '',
          playbook_template_ids: null,
          playbook_contract_ids: null,
          customer_instructions: null,
          extracted_instructions: null,
          playbook_party_role: metadata.playbook_party_role?.value ?? '',
          playbook_party_roles: null,
        },
        systemMetadata: {
          entry_date: '',
          last_updated_date: '',
        },
        customMetadata: { digests: searchResult.digests },
      },
    }
  }

  async function loadPage(query: string) {
    setError('')
    setWarn('')
    setLoading(true)
    fetchingItems.current = true

    const { playbooks, meta, error, warn } = await fetchPlaybooks(query, 0)

    fetchingItems.current = false
    setLoading(false)
    if (warn) setWarn(warn)
    if (error) setError(error)
    if (!playbooks.length) return {}

    return meta
  }

  async function loadNextPage(page: number) {
    setError('')
    setWarn('')

    return
  }

  async function deleteSelectedPlaybook(id: string) {
    if (!id) return
    setDeleting(true)
    const url = `${routes.resourcesUrl}/${id}`

    try {
      setLoading(true)
      setError('')
      await apiClient.delete(url)
      setDeleteModalHidden(true)
    } catch (e) {
      console.error(e)
      setError('Failed to delete playbook.')
      setTimeout(() => {
        setError('Delete timeout. Please try again.')
      }, 10000)
    } finally {
      setDeleting(false)
      setLoading(false)
    }
  }

  const value = {
    query,
    setQuery,
    error,
    setError,
    warn,
    Pagination,
    pageNum,
    topOfPageRef,
    loading,
    setLoading,
    deleteModalHidden,
    setDeleteModalHidden,
    deleting,
    playbooks,
    setPlaybooks,
    fetchPlaybooks,
    loadPage,
    loadNextPage,
    loadFirstPage,
    fetchingItems,
    deleteSelectedPlaybook,
  }

  return <PlaybooksContext.Provider value={value}>{props.children}</PlaybooksContext.Provider>
}

export default PlaybooksContextProvider
