import { type IComboBoxOption } from '@fluentui/react'
import { format, parse } from 'date-fns'
import { FormEvent } from 'react'

import { KeyTermValue } from './KeyTerm'

export type FormSubmitEvent = FormEvent<HTMLFormElement> | { preventDefault: () => void }

export const SHORT_DATE_FORMAT = 'yyyy-MM-dd'
export const DEFAULT_NUM_ITEMS_PER_PAGE = 20

export function friendlyDateTime(dateTime: string | Date): string {
  const dateTimeString =
    !(dateTime instanceof Date) &&
    !(dateTime.toString().endsWith('Z') || dateTime.toString().endsWith('+00:00'))
      ? dateTime.toString() + 'Z'
      : dateTime.toString()
  const [date, time] = new Date(Date.parse(dateTimeString))
    .toLocaleString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
    .split(', ')
  return date + ' at ' + time
}

export function friendlyDate(dateTime: string | Date): string {
  return friendlyDateTime(dateTime).split(' ')[0]
}

export async function hash(str: string) {
  const msg = new TextEncoder().encode(str)
  const buf = await crypto.subtle.digest('SHA-256', msg)
  const bytes = Array.from(new Uint8Array(buf))
  const hex = bytes.map(b => b.toString(16).padStart(2, '0')).join('')

  return hex
}

export function pluralizedText(count: number, noun: string): string {
  return `${count || ''} ${noun}${count === 1 ? '' : 's'}`
}

export function addToArray<T>(arr: T[], item: T) {
  return [...arr, item]
}

export function removeFromArray<T>(arr: T[], item: T) {
  return arr.filter(x => x !== item)
}

export function debounce<T extends any[]>(callback: (...args: T) => void, timeoutMs: number) {
  let timeoutId: NodeJS.Timeout | undefined

  return (...args: T) => {
    clearTimeout(timeoutId)

    timeoutId = setTimeout(() => {
      timeoutId = undefined
      callback(...args)
    }, timeoutMs)
  }
}

export function entries<T extends Record<keyof T, T[keyof T]>>(
  obj: T,
): Array<[string, T[keyof T]]> {
  return Object.entries(obj)
}

export const removeNonNumberValues = (value: KeyTermValue) => {
  const str = value?.toString().replace(/[^0-9-.]/g, '')
  return str ? parseInt(str).toString() : ''
}

export const shortDate = (date?: Date): string => {
  return !date ? '' : format(date, SHORT_DATE_FORMAT)
}

export const parseShort = (date: string, from = new Date()): Date => {
  return parse(date, SHORT_DATE_FORMAT, from)
}

export function serialize(object: any): string {
  return JSON.stringify(object, null, 2)
}

export function extractResourceNameFromUrl(url: string) {
  return new URL(url).pathname.split('/').pop() as string // drop leading slash in pathname
}

export function b64Encode(text: string): string {
  return Buffer.from(text, 'utf-8').toString('base64')
}

export function titleize(text?: string) {
  if (!text) return ''

  return text
    .split(/\s+|_|-/g)
    .map(w => (w ? w[0].toUpperCase() + w.substring(1).toLowerCase() : ''))
    .join(' ')
    .replace(/\s+/g, ' ')
    .trim()
}

export function navigateToExternalSite(path: string) {
  const usingOfficeOnline = Office.context.diagnostics.platform === Office.PlatformType.OfficeOnline
  if (usingOfficeOnline) return window.open(path)

  return Office.context.ui.openBrowserWindow(path)
}

// reload window on dialog closes/errors
export function hardReloadPage(path: string) {
  window.location.href = path
}

export function truncateText(text: string, length = 35) {
  return text.length > length ? text.slice(0, length) + '...' : text
}

export function copyObject(object: any) {
  return Object.assign({}, object)
}

export async function withTimeout<T>(millis: number, promiseName: string, promise: Promise<T>) {
  let timeoutId: NodeJS.Timeout
  const timeout = new Promise<T>((_, reject) => {
    timeoutId = setTimeout(() => {
      reject(new Error(`Timed out executing ${promiseName}`))
    }, millis)
  })
  return Promise.race([promise, timeout]).finally(() => clearTimeout(timeoutId))
}

export function transformToComboBoxOptions<K extends string>(
  data: Record<K, string>[],
  keyKey: K,
  textKey: K,
) {
  return data.reduce((acc, datum) => {
    return [...acc, { key: datum[keyKey], text: datum[textKey] }]
  }, [] as IComboBoxOption[])
}

export function extractFields<T extends Partial<Record<keyof T, T[keyof T]>>>(
  obj: T,
  shouldExtract: (k: keyof T) => boolean,
) {
  return Object.entries(obj).reduce((acc, [key, val]) => {
    if (shouldExtract(key as keyof T)) acc = { ...acc, [key]: val }
    return acc
  }, {} as Partial<T>)
}

export function removeFields<T extends Partial<Record<keyof T, T[keyof T]>>>(
  obj: T,
  shouldRemove: (k: keyof T) => boolean,
) {
  return Object.entries(obj).reduce((acc, [key, val]) => {
    if (!shouldRemove(key as keyof T)) acc = { ...acc, [key]: val }
    return acc
  }, {} as Partial<T>)
}

export function formatNumber(value: number, options: Intl.NumberFormatOptions) {
  try {
    return new Intl.NumberFormat('en-US', options).format(Math.abs(value))
  } catch (e) {
    console.error(e)
    return value
  }
}

export function formatCurrency(value: number, currency = 'USD') {
  return formatNumber(value, { currency, style: 'currency', maximumFractionDigits: 0 })
}

export async function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
