import React, { CSSProperties, PropsWithChildren } from 'react'
import { ActionButton, MessageBar, MessageBarType } from '@fluentui/react'
import useClipboard from 'react-use-clipboard'

type Props = PropsWithChildren & {
  text: string
  successDuration?: number
  style?: CSSProperties
}

const message = 'Copied clause text to clipboard.'

const Clipboard: React.FC<Props> = ({ children, text, successDuration, style }) => {
  const [copied, setCopied] = useClipboard(text, { successDuration })

  return (
    <>
      <ActionButton
        iconProps={{ iconName: 'Copy' }}
        style={{ verticalAlign: 'bottom', ...style }}
        onClick={setCopied}
      >
        {children || 'Copy'}
      </ActionButton>

      {copied && <MessageBar messageBarType={MessageBarType.success}>{message}</MessageBar>}
    </>
  )
}

export default Clipboard
